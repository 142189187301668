import Button from "../../Buttons/Button";
import React, { FormEvent, useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Container, Form } from "./style";
import { secondary } from "../../../theme";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { ModalLoading } from "../../Loadings/ModalLoading";
import { maskFunctions } from "../../../services/maskServices";
import { IGroupProps, IOptionsProps } from "../../../interfaces";
import { signatoriesRelationsOptions } from "../../../utils/options";
import { validateCPF, validateEmail } from "../../../utils/validatesFields";
import { GenericForm, IGenericFormRefProps } from "../../GenericForm";

interface IModalSignatoryProps {
    open: boolean;
    onClose: () => void;
    options?: IOptionsProps[];
    defaultData: any;
    onSave: Function;
    contract_id: string;
    to_remove_ids?: string[];
}

const texts: any = {
    title: {
        new: "Novo signatário",
        edit: "Editar signatário"
    },
    subTitle: {
        new: "Informe os dados abaixo para adicionar um novo signatário",
        edit: "Informe os dados abaixo para editar o signatário"
    }
}

export const ModalSignatory: React.FC<IModalSignatoryProps> = (props) => {
    const { open, onClose, to_remove_ids = [], defaultData, contract_id, onSave } = props;

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger, unregister } = useForm({
        defaultValues: defaultData as any,
        mode: "all"
    });

    const _form = watch()

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [loadingSave, setLoadingSave] = useState(false)

    const form_ref = useRef<IGenericFormRefProps>(null)

    const onSubmit = useCallback(async (data: any) => {

        setLoadingSave(true)

        const dataKeys = Object.keys(data)

        const signatory: any = {}

        for (const key of dataKeys) {
            if (data?.[key]?.value) signatory[key] = data[key].value
            else signatory[key] = data[key]
        }
        if (signatory.cpf) signatory.cpf = maskFunctions.cpf.unMask(signatory.cpf)

        try {

            if (defaultData?.id) await api.put("/signatories/" + defaultData?.id, { contract_id, signatory })
            else await api.post("/signatories", { contract_id, signatory })

            const saveText = defaultData?.id ? "editado" : "cadastrado"

            notify(`Signatário ${saveText} com sucesso`, "success")

            onSave()

        } catch (err) {

            notify("Erro ao salvar signatário", "error")

        }

        setLoadingSave(false)

    }, [form_ref, _form, api, contract_id, defaultData])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, form_ref, _form, api, contract_id, defaultData])

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "customer_id",
                        label: "Nome",
                        type: "select-person",
                        required: true,
                        isClearable: true,
                        get_enabled_change_form_type: () => false,
                        getRemoveIds: (obj: any) => {
                            const customerIds = Object.keys(obj).filter(key => key.startsWith("customer_id"));

                            const customerValues = customerIds.map(id => obj?.[id]?.value)?.filter((value: any) => !!value);

                            const _to_remove_ids = [...to_remove_ids, ...customerValues]

                            return _to_remove_ids
                        },
                        backgroundVisible: true,
                        moreQueries: { type: "PF" },
                        executeOnChange: async (data: any) => {
                            const customer = data?.this

                            if (customer?.id) {
                                const cpf = maskFunctions.cpf.mask(customer?.registration_data?.cpf)
                                const email = customer?.user?.email
                                setValue("cpf", cpf)
                                setValue("email", email)
                            } else {
                                setValue("cpf", null)
                                setValue("email", null)
                                setValue("relation", null)
                            }
                        },
                        getIsDisabled: (data: any) => defaultData?.id
                    }
                ],
                [
                    {
                        name: "email",
                        label: "Email",
                        type: "input",
                        validate: validateEmail,
                        required: true,
                        getIsDisabled: (data: any) => !data["customer_id"]?.value
                    }
                ],
                [
                    {
                        name: "cpf",
                        label: "CPF",
                        type: "input",
                        mask: "cpf",
                        required: true,
                        validate: validateCPF,
                        getIsDisabled: () => true
                    }
                ],
                [
                    {
                        name: "relation",
                        label: "Tipo",
                        type: "select-fixed",
                        required: true,
                        isClearable: true,
                        options: signatoriesRelationsOptions,
                        getIsDisabled: (data: any) => !data["customer_id"]?.value
                    }
                ]
            ]
        }
    ]

    return (
        <>
            <ModalGeneric open={open && !loadingSave} onClose={() => { }}>
                <Container theme={theme}>

                    <ModalHeader
                        title={texts.title[defaultData?.id ? "edit" : "new"]}
                        subTitle={texts.subTitle[defaultData?.id ? "edit" : "new"]}
                        onCancel={() => onClose()}
                        theme={theme}
                    />

                    <Form onSubmit={handleStopPropagation}>

                        <GenericForm
                            ref={form_ref}
                            groups={groups}
                            _form={_form}
                            control={control}
                            errors={errors}
                            trigger={trigger}
                            setValue={setValue}
                            register={register}
                        />

                        <Button
                            type="submit"
                            background={secondary[theme]}
                            color={"#FFF"}
                            style={{ padding: 8, fontSize: 14 }}
                            children="Salvar"
                        />
                    </Form>

                </Container>

            </ModalGeneric>

            <ModalLoading loading={open && loadingSave} theme={theme} />
        </>
    )
}