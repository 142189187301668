import Button from "../../Buttons/Button";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container } from "./style";
import { Bag } from "../../Bags/Bag";
import { MdFlag } from "react-icons/md";
import { Timeline } from "../../Timeline";
import { FormContractModal } from "./form";
import { FaHistory } from "react-icons/fa";
import { useApi } from "../../../hooks/api";
import { BiMailSend } from "react-icons/bi";
import { Column, Line } from "../../../style";
import { ModalHeader } from "../../ModalHeader";
import { IoLogoWhatsapp } from "react-icons/io5";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { ModalAttention } from "../ModalAttention";
import { ModalSignatory } from "../ModalSignatory";
import { IconButton, Tooltip } from "@material-ui/core";
import { BsPersonSquare, BsTrash } from "react-icons/bs";
import { getStatus } from "../../../utils/columnsTables";
import { DocAdd } from "../../SvgComponents/Icons/DocAdd";
import { ModalLoading } from "../../Loadings/ModalLoading";
import { AddSign } from "../../SvgComponents/Icons/AddSign";
import { DocSign } from "../../SvgComponents/Icons/DocSign";
import { maskFunctions } from "../../../services/maskServices";
import { correctDate } from "../../../services/generalServices";
import { DocCancel } from "../../SvgComponents/Icons/DocCancel";
import { DocFinish } from "../../SvgComponents/Icons/DocFinish";
import { SendEmail } from "../../SvgComponents/Icons/SendEmail";
import { FiClock, FiEdit, FiHome, FiSend } from "react-icons/fi";
import { contract_status } from "../../../utils/contract_status";
import { RemoveSign } from "../../SvgComponents/Icons/RemoveSign";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import {
  background,
  borderColors,
  inactiveItens,
  primary,
  secondary,
} from "../../../theme";
import {
  documentEletronicBondsOptions,
  get_corrrect_names,
  signatoriesRelationsOptions,
  signatoryTypesOptions,
} from "../../../utils/options";
import {
  contractsAttributes,
  historyAttributes,
  contracts_customersAttributes,
  propertiesAttributes,
  proposalsAttributes,
} from "../../../interfaces";

interface IModalClientProps {
  onCancel: Function;
  onSave: Function;
  defaultContract?: contractsAttributes | null;
  openModal: boolean;
  backgroundVisible?: boolean;
  property?: propertiesAttributes | null;
  proposal?: proposalsAttributes | null;
}

interface IEvents {
  left: any;
  icon: any;
  right: any;
}

const transformHistory = (
  history: historyAttributes[],
  contract: contractsAttributes
) => {
  let events: IEvents[] = [];
  events = history.map((event: historyAttributes) => {
    const left = (
      <div style={{ fontSize: 14 }}>{correctDate(event.createdAt)}</div>
    );
    let icon = <></>;
    let right = <></>;
    if (event.event === "create_document") {
      icon = <DocAdd color="#000094" />;
      right = (
        <div style={{ fontSize: 14 }}>
          Documento <b>{contract?.name?.toUpperCase()}</b> foi criado com data
          limite para assinatura até <b>{correctDate(contract.expiries_in)}</b>.
        </div>
      );
    } else if (event.event === "add_signatory") {
      icon = <AddSign color="#E20055" />;
      right = (
        <div style={{ fontSize: 14 }}>
          Signatário <b>{event.signatory_email?.toUpperCase()}</b> foi convidado
          a assinar o documento como{" "}
          <b>
            {get_corrrect_names(signatoryTypesOptions)[
              event.sign_as as string
            ]?.toUpperCase()}
          </b>
          .
        </div>
      );
    } else if (event.event === "sign") {
      icon = <DocSign color="#52C41A" />;
      right = (
        <div style={{ fontSize: 14 }}>
          Signatário <b>{event.signatory_name?.toUpperCase()}</b> assinou como{" "}
          <b>
            {get_corrrect_names(signatoryTypesOptions)[
              event.sign_as as string
            ]?.toUpperCase()}
          </b>
          . Validação por token efetuada pelo e-mail{" "}
          <b>{event.signatory_email?.toUpperCase()}</b>. {event.documentation && event.birth && <>
            O Signatário informou
            os dados de <b>CPF {event.documentation}</b> e data de nascimento{" "}
            <b>{event.birth}</b>.
          </>}
        </div>
      );
    } else if (event.event === "cancel_document") {
      icon = <DocCancel color={"#E20055"} />;
      right = (
        <div style={{ fontSize: 14 }}>
          Documento <b>{contract.name?.toUpperCase()}</b> foi cancelado.
        </div>
      );
    } else if (event.event === "finish_document") {
      icon = <DocFinish color={"#52C41A"} />;
      right = (
        <div style={{ fontSize: 14 }}>
          Documento <b>{contract.name?.toUpperCase()}</b> foi fechado com
          sucesso.
        </div>
      );
    } else if (event.event === "send_email") {
      icon = <SendEmail color={"#E20055"} />;
      right = (
        <div style={{ fontSize: 14 }}>
          Solicitação de assinatura enviada manualmente para o signatário{" "}
          <b>{event.signatory_name?.toUpperCase()}</b> no email{" "}
          <b>{event.signatory_email?.toUpperCase()}</b>.
        </div>
      );
    } else if (event.event === "remove_signatory") {
      icon = <RemoveSign color={"#E20055"} />;
      right = (
        <div style={{ fontSize: 14 }}>
          Signatário <b>{event.signatory_email?.toUpperCase()}</b> que assinava
          como{" "}
          <b>
            {get_corrrect_names(signatoryTypesOptions)[
              event.sign_as as string
            ]?.toUpperCase()}
          </b>{" "}
          foi removido do documento.
        </div>
      );
    }
    return { left, icon, right };
  });
  return events;
};

const texts: any = {
  title: {
    new: "Cadastro de Documento Eletrônico",
    edit: "Documento Eletrônico",
  },
  subTitle: {
    new: "Informe os dados necessários para criar um novo documento eletrônico",
    edit: "Acompanhe o status de seu documento eletrônico e tenha acesso aos links de assinatura para cada signatário",
  },
};

export const ModalContract: React.FC<IModalClientProps> = (props) => {
  const {
    onCancel,
    onSave,
    openModal,
    backgroundVisible,
    defaultContract,
    property,
    proposal,
  } = props;

  const { theme, notify } = useGlobal();
  const { api, user, markAsRead } = useApi();

  const [loading_cancel, set_loading_cancel] = useState(false);
  const [openModalNewParty, setOpenModalNewPart] = useState(false);
  const [loading_send_email, set_loading_send_email] = useState<boolean>(false);
  const [loading_search, set_loading_search] = useState<boolean>(
    !!defaultContract?.id
  );
  const [confirm_cancel_contract, set_confirm_cancel_contract] =
    useState<boolean>(false);
  const [loading_remove_signatory, set_loading_remove_signatory] =
    useState<boolean>(false);
  const [modal_confirm_send_email_to_all, set_modal_confirm_send_email_to_all] =
    useState(false);
  const [contract, setContract] = useState<
    contractsAttributes | null | undefined
  >(defaultContract);
  const [signatory_send_email, set_signatory_send_email] =
    useState<contracts_customersAttributes | null>(null);
  const [part_selected_to_edit, set_part_selected_to_edit] =
    useState<contracts_customersAttributes | null>(null);
  const [signatory_selected_to_remove, set_signatory_selected_to_remove] =
    useState<contracts_customersAttributes | null>(null);

  const anchorRef_1 = useRef<HTMLAnchorElement>(null);
  const anchorRef_2 = useRef<HTMLAnchorElement>(null);

  const handleSendEmail = useCallback(async () => {
    set_signatory_send_email(null);

    set_loading_send_email(true);

    try {
      await api.post(
        `/contracts/send_email/${signatory_send_email?.request_signature_key}`,
        {
          contract_id:
            !signatory_send_email?.customer_id &&
              !signatory_send_email?.responsible_id
              ? defaultContract?.id
              : undefined,
        }
      );

      notify("Email enviado com sucesso!", "success");

      search();
    } catch (err) {
      notify("Erro ao enviar e-mail", "error");
    }

    set_loading_send_email(false);
  }, [signatory_send_email, defaultContract]);

  const handleSendEmailToAll = useCallback(async () => {
    set_modal_confirm_send_email_to_all(false);

    set_loading_send_email(true);

    try {
      await api.post(`/contracts/send_email_to_all/${defaultContract?.id}`);

      notify("Emails enviados com sucesso!", "success");

      search();
    } catch (err) {
      notify("Erro ao enviar e-mails", "error");
    }

    set_loading_send_email(false);
  }, [defaultContract]);

  const handleCancel = useCallback(async () => {
    set_confirm_cancel_contract(false);

    set_loading_cancel(true);

    try {
      await api.put(`/contracts/cancel/${contract?.id}`);

      notify("Documento eletrônico cancelado com sucesso!", "success");

      search();
    } catch (err) {
      notify("Erro ao cancelar documento eletrônico!", "error");
    }

    set_loading_cancel(false);
  }, [contract, defaultContract]);

  const search = useCallback(async () => {
    if (defaultContract?.id) {
      set_loading_search(true);

      try {
        const { data } = await api.get("/contracts/" + defaultContract?.id);

        setContract({ ...data });
      } catch (err) {
        notify("Erro ao buscar documentos eletrônicos da proposta", "error");
      }

      set_loading_search(false);
    }
  }, [defaultContract]);

  const getSignatoryDefaultData = (_signatory: any) => {
    if (_signatory?.id) {
      return {
        id: _signatory?.id,
        cpf: _signatory?.cpf as string,
        email: _signatory?.email as string,
        relation: signatoriesRelationsOptions.find(
          (r) => r.value === _signatory?.sign_as
        ),
        customer_id: {
          value: _signatory?.customer_id,
          label: _signatory?.name,
          this: _signatory,
        },
      };
    } else return {};
  };

  const handleSavePart = useCallback(async () => {
    setOpenModalNewPart(false);
    set_part_selected_to_edit(null);
    search();
  }, [defaultContract]);

  const handleCloseModalEditPart = useCallback(() => {
    setOpenModalNewPart(false);
    set_part_selected_to_edit(null);
  }, []);

  const handleRemoveSignatory = useCallback(async (signatory: any) => {
    set_signatory_selected_to_remove(null);
    set_loading_remove_signatory(true);
    try {
      await api.delete(`/signatories/${signatory?.id}`);
      notify("Signatário removido com sucesso!", "success");
      search();
    } catch (err) {
      notify("Erro ao remover signatário!", "error");
    }
    set_loading_remove_signatory(false);
  }, []);

  const handleSelectSignatoryToRemove = useCallback((signatory: any) => {
    set_signatory_selected_to_remove(signatory);
  }, []);

  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    markAsRead({ contract_id: defaultContract?.id });
  }, []);

  const getDefaultData = useCallback(() => {
    if (proposal?.id)
      return {
        bond_type: documentEletronicBondsOptions.find(
          (bond) => bond.value === "proposal"
        ),
        proposal_id: {
          label: `Proposta - #${proposal?.friendly_id}`,
          value: proposal?.id,
          this: proposal,
        },
        expiration_date: 30,
      };
    else if (property?.id)
      return {
        bond_type: documentEletronicBondsOptions.find(
          (bond) => bond.value === "property"
        ),
        property_id: {
          label: `#${property.friendly_id} - ${property.property_type?.name} na ${property.address?.public_place},  ${property.address?.number}, ${property.address?.district}, ${property.address?.city.name} - ${property.address?.state.initials}`,
          value: property?.id,
          this: property,
        },
        expiration_date: 30,
      };
    else return { expiration_date: 30 };
  }, [property, proposal]);

  return (
    <ModalGeneric
      backgroundVisible={backgroundVisible}
      open={openModal}
      onClose={() => { }}
    >
      <Container theme={theme}>
        <ModalHeader
          title={texts.title[defaultContract?.id ? "edit" : "new"]}
          subTitle={texts.subTitle[defaultContract?.id ? "edit" : "new"]}
          onCancel={() => onCancel()}
          theme={theme}
        />

        <ContentOrLoading type="wave" loading={loading_search} theme={theme}>
          <>
            {!contract?.id && (
              <FormContractModal
                onSave={onSave}
                onCancel={onCancel}
                defaultData={getDefaultData()}
              />
            )}
            {contract?.id && (
              <Column style={{ padding: 20, gap: 20, overflow: "auto" }}>
                <Column
                  style={{
                    borderRadius: 5,
                    border: `1px solid ${borderColors[theme]}`,
                    background: primary[theme],
                  }}
                >
                  <Column style={{ padding: 20, gap: 10 }}>
                    <Line
                      style={{
                        justifyContent: "space-between",
                        fontSize: 14,
                        gap: 10,
                        flexWrap: "wrap",
                      }}
                    >
                      <div>Criado em {correctDate(contract.createdAt)}</div>
                      <Line style={{ gap: 5, alignItems: "center" }}>
                        <FiClock />
                        Expira em {correctDate(contract.expiries_in)}
                      </Line>
                    </Line>
                    <div style={{ fontWeight: 600, fontSize: 20 }}>
                      {contract.name?.toUpperCase()}
                    </div>
                    <Line
                      style={{
                        gap: 5,
                        alignItems: "center",
                        fontSize: 14,
                        textTransform: "uppercase",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <MdFlag />
                      </div>
                      <div>Código - #{contract?.friendly_id}</div>
                    </Line>
                    {(contract?.proposal?.id || contract?.property?.id) && (
                      <Line
                        style={{
                          gap: 5,
                          alignItems: "center",
                          fontSize: 14,
                          textTransform: "uppercase",
                        }}
                      >
                        {contract?.proposal?.friendly_id && (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <FiSend />
                            </div>
                            <div>
                              Proposta - #{contract?.proposal?.friendly_id}
                            </div>
                          </>
                        )}
                        {contract?.property?.friendly_id && (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <FiHome />
                            </div>
                            <div>
                              Imóvel - #{contract?.property?.friendly_id}
                            </div>
                          </>
                        )}
                      </Line>
                    )}
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      {getStatus({
                        ...contract_status[contract.status],
                        theme,
                      })}
                    </div>
                  </Column>
                  {(contract.file_original_url ||
                    contract.file_signed_url ||
                    (contract.status === "pending" &&
                      user.type !== "root")) && (
                      <Line
                        style={{
                          gap: 10,
                          padding: 20,
                          borderTop: `1px solid ${borderColors[theme]}`,
                          flexWrap: "wrap",
                        }}
                      >
                        {contract.file_original_url && (
                          <div style={{ minWidth: 90 }}>
                            <Button
                              onClick={() => anchorRef_1.current?.click()}
                              style={{ fontWeight: 600, whiteSpace: "nowrap" }}
                              background={secondary[theme]}
                              color="#FFF"
                            >
                              Baixar Original
                            </Button>
                            <a
                              ref={anchorRef_1}
                              href={contract.file_original_url}
                              target="_blank"
                              style={{ display: "none" }}
                            />
                          </div>
                        )}
                        {contract.file_signed_url && (
                          <div style={{ minWidth: 90 }}>
                            <Button
                              onClick={() => anchorRef_2.current?.click()}
                              style={{ fontWeight: 600, whiteSpace: "nowrap" }}
                              background={inactiveItens[theme]}
                              color="#FFF"
                            >
                              Baixar Assinado
                            </Button>
                            <a
                              ref={anchorRef_2}
                              href={contract.file_signed_url}
                              target="_blank"
                              style={{ display: "none" }}
                            />
                          </div>
                        )}
                        {contract.status === "pending" &&
                          user.type !== "root" && (
                            <>
                              <div style={{ minWidth: 90 }}>
                                <Button
                                  onClick={() =>
                                    set_modal_confirm_send_email_to_all(true)
                                  }
                                  style={{
                                    fontWeight: 600,
                                    whiteSpace: "nowrap",
                                  }}
                                  borderColor={inactiveItens[theme]}
                                  background={inactiveItens[theme]}
                                  color={primary[theme]}
                                >
                                  Solicitar Assinaturas
                                </Button>
                              </div>
                              <div style={{ minWidth: 90 }}>
                                <Button
                                  onClick={() =>
                                    set_confirm_cancel_contract(true)
                                  }
                                  style={{ fontWeight: 600 }}
                                  borderColor={inactiveItens[theme]}
                                  background={primary[theme]}
                                  color={inactiveItens[theme]}
                                >
                                  Cancelar
                                </Button>
                              </div>
                            </>
                          )}
                      </Line>
                    )}
                </Column>
                <Bag
                  icon={<BsPersonSquare size={24} color={secondary[theme]} />}
                  title="Status das assinaturas eletrônicas dos signatários"
                  description={`Este documento possui ${contract?.signatories?.length} signatários(s)`}
                >
                  <Column style={{ flex: 1, padding: 20, gap: 10 }}>
                    <Column
                      style={{
                        gap: 8,
                        fontSize: 13.5,
                        borderRadius: 4,
                        border: `1px solid ${borderColors[theme]}`,
                        overflow: "auto",
                      }}
                    >
                      <table style={{ width: "100%" }}>
                        <thead style={{ background: background[theme] }}>
                          <tr>
                            <th style={{ padding: 10 }}>NOME</th>
                            <th style={{ padding: 10 }}>EMAIL</th>
                            <th style={{ padding: 10 }}>CPF</th>
                            <th style={{ padding: 10 }}>TIPO</th>
                            <th style={{ padding: 10, textAlign: "center" }}>
                              STATUS
                            </th>
                            <th style={{ padding: 10, textAlign: "center" }}>
                              AÇÕES
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {contract?.signatories
                            ?.sort((a, b) => (a.sign_as <= b.sign_as ? 1 : -1))
                            .map((signatory) => (
                              <tr
                                key={signatory.id}
                                style={{ textTransform: "uppercase" }}
                              >
                                <td
                                  style={{ padding: 10, whiteSpace: "nowrap" }}
                                >
                                  {signatory.name}
                                </td>
                                <td
                                  style={{ padding: 10, whiteSpace: "nowrap" }}
                                >
                                  {signatory.email}
                                </td>
                                <td
                                  style={{ padding: 10, whiteSpace: "nowrap" }}
                                >
                                  {signatory.cpf
                                    ? maskFunctions.cpf.mask(signatory.cpf)
                                    : "--"}
                                </td>
                                <td style={{ padding: 10 }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 5,
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: 5,
                                        whiteSpace: "nowrap",
                                        display: "flex",
                                        paddingLeft: 7,
                                        paddingRight: 7,
                                        borderRadius: 3,
                                        background: secondary[theme],
                                        color: "#FFF",
                                        fontWeight: 600,
                                        fontSize: 12,
                                      }}
                                    >
                                      {
                                        get_corrrect_names(
                                          signatoryTypesOptions
                                        )[signatory.sign_as]
                                      }
                                    </div>
                                  </div>
                                </td>
                                <td style={{ padding: 10 }}>
                                  {getStatus({
                                    ...contract_status[signatory.status],
                                    theme,
                                  })}
                                </td>
                                {contract.status === "pending" &&
                                  signatory.status === "pending" &&
                                  user.type !== "root" && (
                                    <td
                                      style={{
                                        padding: 10,
                                        textAlign: "center",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Tooltip
                                        onClick={() =>
                                          document
                                            .getElementById(
                                              `${signatory.id}-wpp-link`
                                            )
                                            ?.click()
                                        }
                                        title="Enviar pelo wpp"
                                        style={{ margin: 0, padding: 10 }}
                                      >
                                        <IconButton aria-label="Enviar pelo wpp">
                                          <IoLogoWhatsapp
                                            color={secondary[theme]}
                                            size={19}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        onClick={() =>
                                          set_signatory_send_email(signatory)
                                        }
                                        title="Enviar email"
                                        style={{ margin: 0, padding: 10 }}
                                      >
                                        <IconButton aria-label="Enviar email">
                                          <BiMailSend
                                            color={secondary[theme]}
                                            size={19}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        onClick={() =>
                                          set_part_selected_to_edit(signatory)
                                        }
                                        title="Editar"
                                        style={{ margin: 0, padding: 10 }}
                                      >
                                        <IconButton aria-label="Editar">
                                          <FiEdit
                                            color={secondary[theme]}
                                            size={19}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      {contract?.signatories.filter(
                                        (s) => s.status === "pending"
                                      )?.length > 1 && (
                                          <Tooltip
                                            onClick={() =>
                                              handleSelectSignatoryToRemove(
                                                signatory
                                              )
                                            }
                                            title="Remover"
                                            style={{ margin: 0, padding: 10 }}
                                          >
                                            <IconButton aria-label="Remover">
                                              <BsTrash
                                                color={secondary[theme]}
                                                size={19}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      <a
                                        id={`${signatory.id}-wpp-link`}
                                        href={`https://api.whatsapp.com/send?text=Olá, ${signatory.name}!%0D%0A%0D%0ASegue abaixo o link para assinatura do documento eletrônico:%0D%0A${signatory.link}`}
                                        target="_blank"
                                        style={{ display: "none" }}
                                      />
                                    </td>
                                  )}
                                {!(
                                  contract.status === "pending" &&
                                  signatory.status === "pending" &&
                                  user.type !== "root"
                                ) && (
                                    <td
                                      style={{ padding: 10, textAlign: "center" }}
                                    >
                                      Nenhuma
                                    </td>
                                  )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </Column>
                    {["pending"].includes(contract.status) && (
                      <Line>
                        <div
                          onClick={() => setOpenModalNewPart(true)}
                          style={{ cursor: "pointer", fontWeight: 600 }}
                        >
                          Incluir+
                        </div>
                      </Line>
                    )}
                  </Column>
                </Bag>
                <Bag
                  icon={<FaHistory size={24} color={secondary[theme]} />}
                  title="Histórico"
                  description="Acompanhe através da linha do tempo todas as atualizações que aconteceram no documento eletrônico dentro do período de criação até a data de hoje."
                >
                  <Column
                    style={{
                      padding: 20,
                      gap: 10,
                      fontSize: 14,
                      overflow: "auto",
                    }}
                  >
                    <Timeline
                      events={transformHistory(
                        JSON.parse(contract.history as any),
                        contract
                      )}
                    />
                  </Column>
                </Bag>
              </Column>
            )}
          </>
        </ContentOrLoading>
      </Container>

      <ModalAttention
        theme={theme}
        open={modal_confirm_send_email_to_all}
        cancelAction={() => set_modal_confirm_send_email_to_all(false)}
        confirmAction={handleSendEmailToAll}
        content={
          <>
            Você deseja solicitar a assinatura de todos os signatários por
            email?
          </>
        }
      />

      <ModalAttention
        theme={theme}
        open={!!signatory_send_email?.click_id}
        cancelAction={() => set_signatory_send_email(null)}
        confirmAction={handleSendEmail}
        content={
          <>
            Você deseja enviar o link para assinatura do signatário{" "}
            <b style={{ textTransform: "uppercase" }}>
              {signatory_send_email?.name}
            </b>{" "}
            no email <b>{signatory_send_email?.email}</b> ?
          </>
        }
      />

      <ModalAttention
        theme={theme}
        open={confirm_cancel_contract}
        cancelAction={() => set_confirm_cancel_contract(false)}
        confirmAction={handleCancel}
        cancelText="Voltar"
        content={
          <>
            Você deseja cancelar o documento eletrônico{" "}
            <b>{contract?.name?.toUpperCase()}</b>?
          </>
        }
      />

      <ModalAttention
        theme={theme}
        open={!!signatory_selected_to_remove?.id}
        cancelAction={() => set_signatory_selected_to_remove(null)}
        confirmAction={() => {
          handleRemoveSignatory(signatory_selected_to_remove);
        }}
        content={
          <>
            Você deseja remover o signatário(
            {
              get_corrrect_names(signatoryTypesOptions)[
              signatory_selected_to_remove?.sign_as as any
              ]
            }
            ) <b>{signatory_selected_to_remove?.name}</b> ?
          </>
        }
      />

      <ModalLoading
        loading={
          loading_send_email || loading_cancel || loading_remove_signatory
        }
        theme={theme}
      />

      {(openModalNewParty || part_selected_to_edit?.id) && (
        <ModalSignatory
          open
          contract_id={contract?.id as string}
          onClose={handleCloseModalEditPart}
          defaultData={getSignatoryDefaultData(part_selected_to_edit)}
          to_remove_ids={
            contract?.signatories
              ?.map((s) => s?.customer_id)
              ?.filter(
                (_id: any) =>
                  !!_id && _id !== part_selected_to_edit?.customer_id
              ) ?? []
          }
          onSave={handleSavePart}
        />
      )}
    </ModalGeneric>
  );
};
